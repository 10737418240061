import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const ContactPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Contact Us" />
      <RunReCaptcha action="contact" />
      <div className={gutter}>
        <h1>Contact Us</h1>

        <p>
          NanoPharmaceuticals LLC
          <br />
          185 Jordan Rd., Suite #4
          <br />
          Troy, NY 12180 USA
          <br />
          Phone: (518) 535-0800
          <br />
          Human Resources:{" "}
          <a href="mailto:hr@nanopharmaceuticals.com">
            hr@nanopharmaceuticals.com
          </a>
          <br />
          General Information:{" "}
          <a href="mailto:info@nanopharmaceuticals.com">
            info@nanopharmaceuticals.com
          </a>
          <br />
          Clinical Trials:{" "}
          <a href="mailto:clinical@nanopharmaceuticals.com">
            clinical@nanopharmaceuticals.com
          </a>
          <br />
          Investors:{" "}
          <a href="mailto:investors@nanopharmaceuticals.com">
            investors@nanopharmaceuticals.com
          </a>
          <br />
          Business Development:{" "}
          <a href="mailto:busdev@nanopharmaceuticals.com">
            busdev@nanopharmaceuticals.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default ContactPage;
